import { useRecoilState } from 'recoil'
import { defaultActiveWithdrawalsFilter } from '../../../../../../../../utils/states/activeFilter.state'
import React, { useEffect, useState } from 'react'
import {
  useGetWithdrawalsQuery,
  useUpdateWithdrawalStatusMutation
} from '../../../../../../../../redux/services/WithdrawalsApi'
import calculateElements from '../../../../../../../../utils/functions/calcucateElements'
import { Blocks } from '../../../../../../../../components/Blocks/Blocks'
import { Selector } from '../../../../../components/Selector/Selector'
import { Pagination } from '../../../../../../../../components/Pagination/Pagination'
import { mapWithdrawals } from '../../../../../../../../utils/functions/map/mapWithdrawals'

const WithdrawalsBlocks = () => {
  const [{ activeWithdrawalsFilter }] = useRecoilState(defaultActiveWithdrawalsFilter)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(7)
  const [openSelector, setOpenSelector] = useState<string | null>(null)
  const { data } = useGetWithdrawalsQuery(
    { status: activeWithdrawalsFilter, page, page_size: pageSize },
    { pollingInterval: 10000 },
  )
  const [updateWithdrawal] = useUpdateWithdrawalStatusMutation()
  const handleEdit = async (newStatus: string, id : string) => {
    const body = {
      status: newStatus,
    }
    await updateWithdrawal({body,id})
  }
  const handleResize = () => {
    setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 7))
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    setPage(1)
  }, [activeWithdrawalsFilter])
  return (
    <>
      <Blocks
        blocks={
          mapWithdrawals(data?.results).map((withdrawal) => {
            return (
              <div
                className={'block'}
                key={withdrawal.id}
                style={withdrawal.statusId === 'BN' ? { background: '#FFF5F5' } : {}}
              >
                <div className={'component'}>
                  <p className={'block-component'}>UID:</p>
                  <p className={'block-value'}>{withdrawal.id}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Статус:</p>
                  <p className={'block-value'}>
                    <Selector
                      open={openSelector}
                      setOpen={setOpenSelector}
                      id={withdrawal.id}
                      status={withdrawal.status}
                      statusId={withdrawal.statusId}
                      pageName={'withdraw'}
                      handleEdit={handleEdit} />
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Реквизиты:</p>
                  <p className={'block-value'}>
                    {withdrawal.card_number}
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Ожидается:</p>
                  <p className={'block-value'}>{withdrawal.expected_income}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Дата:</p>
                  <p className={'block-value'}>{withdrawal.date}</p>
                </div>
              </div>
            )
          }) || []
        }
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </>
  )
}
export default WithdrawalsBlocks