import styles from './Header.module.css'
import { useGetSelfQuery } from '../../../../../redux/services/TraderApi'
import React from 'react'
import formatNumber from '../../../../../utils/functions/formatNumber'
import { Loading } from '../../../../../components/Loading/Loading'

export const Rate = () => {
  const { data: trader, isLoading: TraderLoading } = useGetSelfQuery(undefined, {
    pollingInterval: 5000,
  })
  if (TraderLoading) {
    return <Loading />
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        Bybit USDT-{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.course))}</div>
      </div>
      <div className={styles.block}>
        Ваша ставка:
        <div className={styles.item}>
          <p style={{color : "#4DB84B"}}>
            <img src={'/greenArrowIcon.svg'} alt={'down arrow'} />
            {trader?.income_course} ({trader?.percent}%)
          </p>
        </div>
      </div>
      <div className={styles.block}>
        Курс СПБ:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.outcome_course_sbp))} ({trader?.outcome_percent_sbp}%)</div>
      </div>
      <div className={styles.block}>
        Курс СберБанк:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.outcome_course_sber))} ({trader?.outcome_percent_sber}%)</div>
      </div>
      <div className={styles.block}>
        Курс другие банки:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.outcome_course_other))} ({trader?.outcome_percent_other}%)</div>
      </div>
    </div>
  )
}
