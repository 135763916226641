import { useRecoilState } from 'recoil'
import { defaultActiveWithdrawalsFilter } from '../../../../../../../../utils/states/activeFilter.state'
import React, { useEffect, useState } from 'react'
import { useGetWithdrawalsQuery, useUpdateWithdrawalStatusMutation } from '../../../../../../../../redux/services/WithdrawalsApi'
import calculateElements from '../../../../../../../../utils/functions/calcucateElements'
import { Table } from '../../../../../../../../components/Table/Table'
import { withdrawalsThArray } from '../../../../../../../../utils/constants/th_arrays'
import { mapWithdrawals } from '../../../../../../../../utils/functions/map/mapWithdrawals'
import { Pagination } from '../../../../../../../../components/Pagination/Pagination'
import { Selector } from '../../../../../components/Selector/Selector'
import styles from '../../../../../components/Filter/Filter.module.css';
const WithdrawalsTable = () => {
  const [{ activeWithdrawalsFilter }] = useRecoilState(defaultActiveWithdrawalsFilter)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(7)
  const [openSelector, setOpenSelector] = useState<string | null>(null)
  const [searchId, setSearchId] = useState<string>()
  const { data } = useGetWithdrawalsQuery(
    { status: activeWithdrawalsFilter, page, page_size: pageSize, id: searchId?.trim() || '' },
    { pollingInterval: 10000 }
  )
  const [updateWithdrawal] = useUpdateWithdrawalStatusMutation()
  const handleEdit = async (newStatus: string, id : string) => {
    const body = {
      status: newStatus,
    }
    await updateWithdrawal({body,id})
  }
  const handleResize = () => {
    setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 7))
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    setPage(1)
  }, [activeWithdrawalsFilter])

  return (

      <div>
        <div style={{width: '100%', display: 'flex', maxWidth: '440px'}}>
            <span style={{
              width: '70%',
              display: 'flex',
              marginBottom: '12px',
              alignItems: 'center'
            }}>Найдите заявку:</span>
          <input className={styles.input} type={'text'} value={searchId} onChange={(e) => setSearchId(e.target.value)}/>
        </div>
      <Table
        thArray={withdrawalsThArray}
        tdArray={mapWithdrawals(data?.results)?.map((withdrawal) => {
          const style = withdrawal.statusId == 'BN' ? { background: '#FFF5F5' } : {}
          return [
            { element: withdrawal.id, style },
            {
              element: <Selector
                open={openSelector}
                setOpen={setOpenSelector}
                id={withdrawal.id}
                status={withdrawal.status}
                statusId={withdrawal.statusId}
                pageName={'withdraw'}
                handleEdit={handleEdit} />,
              style:
                withdrawal.statusId == 'BN'
                  ? { background: '#FFF5F5', padding: '0' }
                  : { wordBreak: 'normal', padding: '0' },
            },
            { element: withdrawal.card_number, style },
            { element: withdrawal.bank_full_name || 'Другой банк', style },
            { element: withdrawal.account_extra_info ?? '-', style },
            { element: withdrawal.expected_income, style },
            {
              element: withdrawal.date,
              style:
                withdrawal.statusId == 'BN'
                  ? { background: '#FFF5F5', wordBreak: 'normal' }
                  : { wordBreak: 'normal' },
            },
          ]
        }) || []}
      > </Table>
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </div>
  )
}

export default WithdrawalsTable