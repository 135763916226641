export const transformFunction = (text: string | undefined, isInteger: boolean = false) => {
  if (text) {
    let formattedText = text.replace(/[^0-9.]/g, '');
    const parts = formattedText.split('.');
    if (isInteger) {
      return parts[0];
    }
    if (parts.length > 2) {
      formattedText = parts[0] + '.' + parts.slice(1).join('');
    }
    if (parts.length === 2) {
      formattedText = parts[0] + '.' + parts[1].slice(0, 2);
    }
    return formattedText.trim();
  }
  return text;
};

export const paymentTypeOptions = [
  {name: 'SBP', id: 'SBP'},
  {name: 'C2C', id: 'C2C'},
  {name: 'TRANSFER', id: 'TRANSFER'}
]

export const paymentNotificationTypeOptions = [
  {name: 'SMS', id: 'SMS'},
  {name: 'PUSH', id: 'PUSH'},
]

export const locateKeys: any = {
  account_number: 'Номер карты',
  tag: 'Название реквизита (тег)',
  payment_type: 'Метод оплаты',
  phone_number: 'Номер телефона',
  fio: 'ФИО',
  description: 'Описание',
  min_income: 'Минимальная сумма платежа',
  max_income: 'Максимальная сумма платежа',
  instant_active_orders: 'Заявок одновременно не более',
  bank: 'Наименование банка',
}