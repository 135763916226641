import React, { useState } from 'react'
import { CardsBlocks } from './components/CardsBlocks/CardsBlocks'
import { CardsTable } from './components/CardsTable/CardsTable'
import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../../../../utils/states/isMobile.state'
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form'
import { type IInputsEditCard } from './components/components/ChangeDescriptionInput/ChangeDescriptionInput'
import { parseStringToNumber } from '../../../../../utils/functions/parseNumber'
import { useEditCardMutation } from '../../../../../redux/services/CardsApi'
import { ModalDefault } from "../../../../../components/ModalDefault/ModalDefault";
import styles from "../AddCard/styles.module.css";
import { Button } from "../../../../../components/Button/Button";
import { locateKeys } from "../AddCard/utils";

export interface TCardsContentProps {
  currentId: null | number
  setCurrentId: React.Dispatch<React.SetStateAction<null | number>>
}

export const CardsContent = () => {
  const [errorList, setErrorList] = useState<any>({})
  const [cardExist, setCardExist] = useState<boolean>(false)
  const [version] = useRecoilState(defaultIsMobile)
  const [currentId, setCurrentId] = useState<number | null>(null)
  const formMethods = useForm<IInputsEditCard>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const { handleSubmit, reset } = formMethods
  const [updateCard] = useEditCardMutation()
  const handleEdit: SubmitHandler<IInputsEditCard> = async (data) => {
    const body = {
      total_limit: parseStringToNumber(data.newLimit),
      description: data.newDescription,
      min_income: data.newMinIncome,
      max_income: data.newMaxIncome,
      instant_active_orders: data.newInstantOrders
    }
    await updateCard({ cardId: Number(currentId), body })
      .unwrap()
      .then(() => {
        setCurrentId(null)
        reset()
      })
      .catch((error) => {
        if (error.status === 400) {
          setErrorList(error.data);
          setCardExist(true)
        }
      })
  }
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleEdit)}>
        {version.isMobile ? (
          <CardsBlocks currentId={currentId} setCurrentId={setCurrentId} />
        ) : (
          <CardsTable currentId={currentId} setCurrentId={setCurrentId} />
        )}
        <ModalDefault
          title={'Ошибка'}
          titleMargin={'8px'}
          textMargin={'24px'}
          active={cardExist}
          setActive={setCardExist}
          text={''}
        >
          <div>
            {Object.keys(errorList).map((key) =>
              <div className={styles.errorItem} key={key}>
                {locateKeys[key] && <b>{ locateKeys[key] }:</b>} { errorList[key]?.[0] }
              </div>
            )}
          </div>
          <Button
            action={() => {
              setCardExist(false)
            }}
            text={'Ок'}
            style={{ marginRight: '8px' }}
          />
        </ModalDefault>
      </form>
    </FormProvider>
  )
}
