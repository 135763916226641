import { type thModel } from '../types'

export const ordersThArray = [
  { name: 'UID', style: { width: '18.5%' } },
  { name: 'Статус', style: { width: '15.5%' } },
  { name: 'Реквизиты', style: { width: '16.5%' } },
  { name: 'Банк', style: { width: '12.5%' } },
  { name: 'Ожидается', style: { width: '8%' } },
  { name: 'Пришло', style: { width: '8%' } },
  { name: 'Дата', style: { width: '10.5%' } },
  { name: 'Срок действия', style: { width: '7%' } },
]

export const withdrawalsThArray = [
  { name: 'UID', style: { width: '20%' } },
  { name: 'Статус', style: { width: '17%' } },
  { name: 'Реквизиты', style: { width: '18%' } },
  { name: 'Банк', style: { width: '15%' } },
  { name: 'Примечание, доп.инфо', style: { width: '5%' } },
  { name: 'Ожидается', style: { width: '10%' } },
  { name: 'Дата', style: { width: '15%' } },
]
export const overflowThArray: thModel[] = [
  { name: 'Хэш транзакции', style: { width: '40%', textAlign: 'center' } },
  { name: 'Статус', style: { width: '20%', textAlign: 'center' } },
  { name: 'Сумма (USDT)', style: { width: '20%', textAlign: 'center' } },
  { name: 'Дата создания', style: { width: '20%', textAlign: 'center' } },
]
export const cardThArray: thModel[] = [
  { name: 'Активность' },
  { name: 'Название реквизита' },
  { name: 'Описание' },
  { name: 'Метод оплаты' },
  { name: 'Номер карты' },
  { name: 'Номер счета' },
  { name: 'БИК'},
  { name: 'Банк' },
  { name: 'Назначение платежа' },
  { name: 'Номер телефона' },
  { name: 'Общий лимит' },
  { name: 'ФИО' },
  { name: 'Всего пополнений' },
  { name: 'Минимальная сумма платежа' },
  { name: 'Максимальная сумма платежа' },
  { name: 'Заявок одновременно не более' },
  { name: 'Действие' },
]
export const settingsThArray: thModel[] = [
  { style: { width: '20%' }, name: 'Имя устройства' },
  { style: { width: '20%' }, name: 'ID Устройства' },
  { style: { width: '20%' }, name: 'Версия приложения' },
  { style: { width: '20%' }, name: 'Статус' },
  { style: { width: '20%' }, name: 'Действия' },
]
